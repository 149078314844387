export default {
    MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-uploads-vp"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://7fen0m8gc2.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_0pBcfHI5p",
      APP_CLIENT_ID: "hourcda8j62pfakkq4b6ppmn9",
      IDENTITY_POOL_ID: "us-east-1:124ddf4a-7ac9-43aa-825f-8307e6e4aa23"
    }
  };
  