import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Delete(s3Key) {
  const removed = await Storage.vault.remove(s3Key);
  console.log("Deleted attachment with key: "+s3Key);  
  return removed;
}
